import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Label from '../../../common/Label';
import Text from '../../../common/Text';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import OutlineCheckmarkIcon from '../../../icons/OutlineCheckmarkIcon';
const VerifiedView = React.memo(() => {
    const t = useTranslation();
    return (_jsxs("div", { className: "border-md flex flex-col items-center justify-center px-8 pb-[5.9375rem] pt-[1.5625rem] text-center", children: [_jsx(OutlineCheckmarkIcon, { className: "mb-[1.0625rem]" }), _jsx(Label, { className: "mb-[0.3125rem]", size: "normal", testId: "gated-email-verified", children: t('gated-tour.email-verified') }), _jsx(Text, { className: "leading-7 tracking-[0.01em]", size: "medium", children: t('gated-tour.have-fun') })] }));
});
VerifiedView.displayName = 'VerifiedView';
export default VerifiedView;
