import type { AssetConfig, Pixel, Size, Subscription } from '@g360/vt-types';
import { transposeM4 } from '@g360/vt-utils';
import urljoin from 'url-join';

import Utils, { getPerspectiveMatrix } from '../../common/Utils';
import getColorCorrectionUniformSettings from '../../common/Utils/getColorCorrectionUniformSettings';
import {
  createTexture,
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
  setTextureFromImage,
} from '../../common/webglUtils';
import type Renderer from '../../mixins/Renderer';
import type { ColorCorrectionUniformSettings, Image, ProgramName } from '../../types/internal';
import type MeasureToolProgram from '../MeasureToolProgram';
import fragmentShaderSource from './cube.fs.glsl';
import vertexShaderSource from './cube.vs.glsl';
import fragmentShaderColorCorrectionSource from './cubeColorCorrection.fs.glsl';
import Tile from './Tile';

// TODO(uzars): improve type-checking
type CubeProgramEvents = 'render' | 'error.set' | 'error.clear';

class CubeProgram {
  name: ProgramName;
  orderIndex = 0;

  yaw = 0;
  pitch = 0;
  fov = Utils.toRad(120);

  forcePreview = false;
  alpha = 1.0;
  yawOffset = 0;
  isPreloadPano = false;

  measureToolProgram?: MeasureToolProgram;

  colorCorrectionUniformSettings: ColorCorrectionUniformSettings;

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private renderer: Renderer;
  private assetConfig: AssetConfig;
  private colorCorrectionMode = false;

  private vertexBuffer: WebGLBuffer | null;
  private textureBuffer: WebGLBuffer | null;
  private vertexAttribute = 0;
  private textureAttribute = 0;
  private perspectiveUniform: WebGLUniformLocation | null = null;
  private alphaUniform: WebGLUniformLocation | null = null;
  private alphaFixUniform: WebGLUniformLocation | null = null;
  private samplerUniform: WebGLUniformLocation | null = null;

  private colorCorrectionMatrixUniformLocation: WebGLUniformLocation | null = null;
  private colorCorrectionOffsetUniformLocation: WebGLUniformLocation | null = null;
  private colorBalanceVectorUniformLocation: WebGLUniformLocation | null = null;
  private shadowsUniformLocation: WebGLUniformLocation | null = null;
  private highlightsUniformLocation: WebGLUniformLocation | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private ready = false;

  private rotatedPerspective: number[] = [];

  private tileLoadQueue: Tile[] = [];
  private tiles: Tile[] = [];
  private tilesPath?: string;
  private optimalRenderedLevel = 3;
  private maxRenderedLevel = 3;
  private abortController = window.AbortController ? new AbortController() : null;
  private numDownloading = 0;
  private highResMode = false;

  private listeners: { [key: string]: ((payload) => void)[] } = {};

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    assetConfig: AssetConfig,
    colorCorrectionMode: boolean,
    name?: ProgramName
  ) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.assetConfig = assetConfig;
    this.colorCorrectionMode = colorCorrectionMode;
    this.name = name ?? 'PanoProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    this.colorCorrectionUniformSettings = getColorCorrectionUniformSettings();

    // Initialize tile structure and default textures
    for (let sideIter = 0; sideIter < 6; sideIter += 1) {
      const sideKey = Tile.sideKeys[sideIter];

      this.tiles.push(
        new Tile(
          Tile.sideKeys[sideIter],
          Tile.faceGeometry[sideKey],
          `/${sideKey}/l${1}/${1}/l${1}_${sideKey}_${1}_${1}.jpg`,
          1,
          null
        )
      );
    }

    this.drawToFramebufferTexture = this.drawToFramebufferTexture.bind(this);
  }

  /** Used to fetch the signed tile images both in CubeProgram and ScenePreloader */
  static fetchTileImage(
    tilePath: string,
    assetConfig: AssetConfig,
    abortController: AbortController | null
  ): Promise<Image | undefined> {
    return Utils.fetchSignedImage(tilePath, assetConfig, abortController);
  }

  init(): void {
    if (this.colorCorrectionMode) {
      this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderColorCorrectionSource);
    } else {
      this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);
    }

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.textureAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.perspectiveUniform = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.alphaFixUniform = this.gl.getUniformLocation(this.program, 'u_alpha_fix');
      this.samplerUniform = this.gl.getUniformLocation(this.program, 'u_sampler');
      this.vertexAttributes = [this.vertexAttribute, this.textureAttribute];

      if (this.colorCorrectionMode) {
        this.colorCorrectionMatrixUniformLocation = this.gl.getUniformLocation(this.program, 'u_colorCorrectionMatrix');
        this.colorCorrectionOffsetUniformLocation = this.gl.getUniformLocation(this.program, 'u_colorCorrectionOffset');
        this.colorBalanceVectorUniformLocation = this.gl.getUniformLocation(this.program, 'u_colorBalance');
        this.shadowsUniformLocation = this.gl.getUniformLocation(this.program, 'u_shadows');
        this.highlightsUniformLocation = this.gl.getUniformLocation(this.program, 'u_highlights');
      }
    }

    this.ready = true;
  }

  /** Delete all created textures, abort pending loads and clear queue */
  destroy(): void {
    this.ready = false;

    const destroyTile = (tile: Tile) => {
      // Explicitly delete texture
      this.gl.deleteTexture(tile.textureObject);

      if (tile.children) {
        tile.children.forEach(destroyTile);
      }
    };

    this.abortPending();
    this.tileLoadQueue = [];
    this.tiles.forEach(destroyTile);

    this.destroyEventEmitter();
    destroyProgram(this.gl, this.program);
  }

  /** Abort all pending fetch requests */
  abortPending(): void {
    this.tileLoadQueue = [];

    if (this.abortController) {
      this.abortController.abort();
    }
  }

  /** Find optimal resolution level based on canvas size and scene zoom (fov) */
  setOptimalLevel(): void {
    if (this.highResMode) {
      this.optimalRenderedLevel = 4;
      return;
    }

    // TODO(uzars): need to revise this
    const maxLevel = Tile.levelSizes.length;
    const fov = (this.fov * 180) / Math.PI;
    const projectionPixelsList = Tile.levelSizes.map((size) => (size * 4 * fov) / 360);
    const viewportPixels = window.innerWidth * (1.4 + 0.4 + (window.devicePixelRatio - 1) * 0.2);

    let newLevel = maxLevel;

    for (let i = 0; i < maxLevel; i += 1) {
      if (viewportPixels < projectionPixelsList[i]) {
        newLevel = i + 1;
        break;
      }
    }

    this.optimalRenderedLevel = newLevel;
  }

  /** Load the preview.jpg image for the fastest first paint, this is needed only for the initial scene load
   *  or if we fail to load watermarks - then L1 is replaced with preview and L1+ is disabled.
   */
  async loadPreview(tilesPath: string): Promise<void> {
    this.tilesPath = tilesPath;
    const previewPath = urljoin(tilesPath, 'preview.jpg');

    await Utils.fetchSignedImage(previewPath, this.assetConfig, this.abortController).then((image?: Image) => {
      if (!image) return;

      // Same texture is used for all sides, only texture coordinates are changed
      const textureObject = createTexture(this.gl, [255, 255, 255, 0.0]);
      setTextureFromImage(this.gl, textureObject, image);
      Utils.closeImage(image);

      // Disable multi-res levels
      this.tiles.forEach((tile) => {
        tile.disableAll();
      });

      // prettier ignore
      const sideIndex = { f: 1, b: 3, u: 4, d: 5, l: 0, r: 2 };
      const pixelOffset = 0.0003;
      const sizeOffset = 1 / 6;

      Tile.sideKeys.forEach((sideKey) => {
        const textureCoords = new Float32Array([
          0,
          pixelOffset + sideIndex[sideKey] * sizeOffset,
          1,
          pixelOffset + sideIndex[sideKey] * sizeOffset,
          1,
          sizeOffset - pixelOffset + sideIndex[sideKey] * sizeOffset,
          0,
          sizeOffset - pixelOffset + sideIndex[sideKey] * sizeOffset,
        ]);

        const tile = new Tile(sideKey, Tile.faceGeometry[sideKey], 'preview.jpg', 1, null, null, textureCoords)
          .setTextureObject(textureObject)
          .setAsPreview();

        this.tiles.push(tile);
      });

      this.emit('render');
    });
  }

  /** Load tiles using queue */
  loadTiles(tilesPath?: string): void {
    if (this.forcePreview) {
      return;
    }

    if (tilesPath) {
      this.tilesPath = tilesPath;
    }

    this.tiles.forEach((tile) => {
      if (!tile.preview) {
        tile.disableAll(false);
      }
    });

    this.emit('render');
  }

  /** Load tiles without queue as fast as possible */
  async preload(tilesPath: string): Promise<void> {
    if (this.forcePreview) {
      await this.loadPreview(tilesPath);
      return;
    }

    const promiseList: Promise<void>[] = [];
    this.tilesPath = tilesPath;

    this.tiles.forEach((tile_) => {
      if (!tile_.preview) {
        promiseList.push(
          new Promise((resolve) => {
            tile_.loading = true;
            const tilePath = urljoin(tilesPath, tile_.path);

            CubeProgram.fetchTileImage(tilePath, this.assetConfig, this.abortController).then((image?: Image) => {
              if (image) {
                if (!tile_.textureObject) {
                  tile_.textureObject = createTexture(this.gl);
                }

                setTextureFromImage(this.gl, tile_.textureObject, image);

                tile_.loaded = true;
                tile_.disabled = false;
              }

              resolve();
            });
          })
        );
      }
    });

    await Promise.all(promiseList);
  }

  /**
   * Sets high resolution mode to load the highest level tiles
   * @param enableHighRes - enable high resolution mode
   */
  setHighResMode(enableHighRes: boolean): void {
    this.highResMode = enableHighRes;
    this.setOptimalLevel();
    this.draw();
  }

  subscribe(event: CubeProgramEvents, fn: (payload) => void): Subscription {
    if (event !== undefined) {
      this.listeners[event] = this.listeners[event] || [];

      if (!this.listeners[event].filter((c) => c !== fn).length) {
        this.listeners[event].push(fn);
      }
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, fn);
      },
    };
  }

  unsubscribe(event: CubeProgramEvents, fn: (payload) => void): void {
    this.listeners[event] = this.listeners[event].filter((c) => c !== fn);
  }

  render(): void {
    if (!this.gl || !this.ready) return;

    const { renderer } = this;
    if (renderer.renderMode !== 'pano') return;

    // Transition rendering
    if (renderer.isInTransition) {
      const isDoublePanoBlend = !renderer.transitionConnected || renderer.engine.watermarkInterrupted;

      if (isDoublePanoBlend) {
        if (this.isPreloadPano) this.alpha = renderer.transitionProgress;
        this.draw(true);
        if (this.isPreloadPano) renderer.render();
      }

      return;
    }

    const { cameraMoved } = renderer;

    // Normal rendering with measure tool
    if (this.measureToolProgram) {
      renderer.nextDrawMoved = cameraMoved;

      if (cameraMoved) {
        renderer.shouldDrawAfterMove = true;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.needsToDrawFb) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.shouldDrawAfterMove) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.shouldDrawAfterMove = false;
      }

      return;
    }

    // Normal rendering
    this.draw(cameraMoved);
  }

  draw(moved = false): void {
    if (!this.gl || !this.ready) return;

    this.loadQueuedTile();

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.activeTexture(this.gl.TEXTURE1);

    if (moved || !this.rotatedPerspective.length) {
      const size: Size<Pixel> = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
      this.rotatedPerspective = getPerspectiveMatrix(this.fov, size, 0.1, 100.0, -this.pitch, this.yaw + this.yawOffset); // prettier-ignore
      this.gl.uniformMatrix4fv(this.perspectiveUniform, false, new Float32Array(transposeM4(this.rotatedPerspective)));
    }

    this.maxRenderedLevel = this.optimalRenderedLevel;
    if (moved && this.optimalRenderedLevel > 1) {
      this.maxRenderedLevel = this.optimalRenderedLevel - 1;
    }

    this.tiles.forEach((tile) => {
      this.checkTile(tile);
    });

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }

  private drawToFramebufferTexture(): void {
    this.draw(this.renderer.nextDrawMoved);
  }

  /** Sort tiles based on screen space coordinates and level - lowest level and tiles closest to the center
   * of the viewport are first */
  private sortQueuePriority(): void {
    this.tileLoadQueue.sort((a, b) => {
      if (a.level > b.level) return -1;
      if (a.level < b.level) return 1;

      return b.diff - a.diff;
    });
  }

  /** Load tile image and add it to the texture loading queue */
  private loadTile(tile_: Tile): void {
    if (this.tilesPath) {
      tile_.loading = true;
      tile_.resetTextureCoords();
      const tilePath = urljoin(this.tilesPath, tile_.path);

      this.numDownloading += 1;
      Utils.fetchSignedImage(tilePath, this.assetConfig, this.abortController).then((image?: Image) => {
        if (image) {
          this.numDownloading -= 1;
          this.addToTileQueue(image, tile_);
          this.emit('render');
        }
      });
    }
  }

  /** Load tile texture to the GPU and setup state for parent tile */
  private loadTileTexture(image: Image, tile_: Tile): void {
    if (!image) return;

    // TODO(uzars): improve error capture
    if (!tile_.textureObject) {
      tile_.textureObject = createTexture(this.gl);
    }

    setTextureFromImage(this.gl, tile_.textureObject, image);

    tile_.loaded = true;

    // Check if all sibling tiles are loaded
    if (tile_.parent) {
      tile_.parent.checkChildrenLoaded();
    }

    // Delete preview tile if this tile is level 1 (l1 replaces preview)
    if (tile_.level === 1 && !this.forcePreview) {
      this.tiles = this.tiles.filter((baseTile) => !(baseTile.sideKey === tile_.sideKey && baseTile.preview));
    }

    Utils.closeImage(image);
    this.emit('render');
  }

  /** Take and run texture loading task from the queue, only a single texture is loaded in an animation frame */
  private loadQueuedTile(): void {
    if (this.tileLoadQueue.length) {
      const tile = this.tileLoadQueue.pop();

      if (tile?.loadTexture) {
        tile.loadTexture();
      }
    }
  }

  /** Add callable texture loading task to the queue */
  private addToTileQueue(image: Image, tile_: Tile): void {
    tile_.loadTexture = () => {
      this.loadTileTexture(image, tile_);
    };

    tile_.setDiff(this.rotatedPerspective);

    this.tileLoadQueue.unshift(tile_);

    this.sortQueuePriority();
  }

  private checkTile(tile: Tile): void {
    if (tile.disabled || tile.level > this.maxRenderedLevel) return;
    if (tile.level < this.maxRenderedLevel && !tile.isVisible(this.rotatedPerspective)) return;
    if (tile.parent && !tile.parent.loaded) return;

    if (!tile.loading) {
      this.loadTile(tile);
    } else if (tile.loaded) {
      if (!tile.childrenLoaded || this.maxRenderedLevel === tile.level) {
        this.drawTile(tile);
      }
    } else {
      return;
    }

    if (tile.children) {
      tile.children.forEach((childTile) => {
        this.checkTile(childTile);
      });
    }
  }

  private drawTile(tile: Tile): void {
    if (this.colorCorrectionMode) {
      this.gl.uniformMatrix4fv(
        this.colorCorrectionMatrixUniformLocation,
        false,
        this.colorCorrectionUniformSettings.colorCorrectionMatrix
      );
      this.gl.uniform4fv(
        this.colorCorrectionOffsetUniformLocation,
        this.colorCorrectionUniformSettings.colorCorrectionOffset
      );
      this.gl.uniform3fv(
        this.colorBalanceVectorUniformLocation,
        this.colorCorrectionUniformSettings.colorBalanceVector
      );
      this.gl.uniform1f(this.shadowsUniformLocation, this.colorCorrectionUniformSettings.shadows);
      this.gl.uniform1f(this.highlightsUniformLocation, this.colorCorrectionUniformSettings.highlights);
    }

    // Set texture coordinates
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, tile.textureCoords, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.textureAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1f(this.alphaUniform, this.alpha);
    this.gl.uniform1i(this.alphaFixUniform, 0);

    this.gl.uniform1i(this.samplerUniform, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, tile.geometry, this.gl.STATIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindTexture(this.gl.TEXTURE_2D, tile.textureObject);
    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
  }

  /** Destroy emitter by removing all subscribers */
  private destroyEventEmitter(): void {
    this.listeners = {};
  }

  private emit(event: CubeProgramEvents, payload?): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(payload));
    }
  }
}

export default CubeProgram;
