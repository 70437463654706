var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import noop from 'lodash/noop';
import React, { useCallback, useMemo, useState } from 'react';
import Input from '../../../common/Input';
import TextButton from '../../../common/TextButton';
import PhoneInput from '../../../components/PhoneInput';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import validator from '../../../utils/validator';
import EmailConsentCheckBox from '../components/EmailConsentCheckBox';
import { genericGatedTourErrorMessage, validateEmail } from '../utils/gatedTourUtils';
const ProvideInfoView = React.memo(({ onEmailRequested = noop }) => {
    const t = useTranslation();
    const { gatedTourService, layoutService, phoneNumberService } = useServices();
    const { analytics } = useGlobalContext();
    const { email, fullName, fullPhoneNumber, callingCode, isEmailConsentAccepted, requestEmailCooldownEmail, requestEmailCooldown, } = useReactive(gatedTourService, [
        'email',
        'fullName',
        'fullPhoneNumber',
        'callingCode',
        'isEmailConsentAccepted',
        'requestEmailCooldownEmail',
        'requestEmailCooldown',
    ]);
    const [emailError, setEmailError] = useState('');
    const [fullNameError, setFullNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const validateInput = useMemo(() => validator(phoneNumberService, t), [phoneNumberService, t]);
    const isNationalNumberEmpty = useMemo(() => fullPhoneNumber.trim() === callingCode, [callingCode, fullPhoneNumber]);
    const onEmailChange = useCallback((event) => {
        gatedTourService.email = event.target.value;
        setEmailError('');
    }, [gatedTourService]);
    const onFullNameChange = useCallback((event) => {
        gatedTourService.fullName = event.target.value;
        setFullNameError('');
    }, [gatedTourService]);
    const onPhoneNumberChange = useCallback((newFullPhoneNumber, newCallingCode) => {
        gatedTourService.fullPhoneNumber = newFullPhoneNumber;
        gatedTourService.callingCode = newCallingCode !== null && newCallingCode !== void 0 ? newCallingCode : '';
        setPhoneNumberError('');
    }, [gatedTourService]);
    const onEmailConsentChange = useCallback((event) => {
        gatedTourService.isEmailConsentAccepted = event.target.checked;
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'GATE', 'Data consent', {
            checked: event.target.checked,
        });
    }, [analytics, gatedTourService]);
    const onContinueClick = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'GATE', 'Send code');
        setEmailError('');
        setFullNameError('');
        setPhoneNumberError('');
        const isEmailValidated = validateEmail(email);
        if (!isEmailValidated)
            setEmailError('gated-tour.email-invalid');
        const newFullNameError = validateInput.name(fullName, true);
        if (newFullNameError)
            setFullNameError(newFullNameError);
        const newPhoneNumberError = validateInput.phone(fullPhoneNumber, true);
        if (newPhoneNumberError)
            setPhoneNumberError(newPhoneNumberError);
        if (!isEmailValidated || newFullNameError || newPhoneNumberError)
            return;
        try {
            yield gatedTourService.requestEmail();
        }
        catch (error) {
            layoutService.addToastMessage(error instanceof Error ? error.message : genericGatedTourErrorMessage);
            return;
        }
        onEmailRequested();
    }), [analytics, email, fullName, fullPhoneNumber, gatedTourService, layoutService, onEmailRequested, validateInput]);
    return (_jsxs("div", { children: [_jsx("div", { className: "px-8 pb-6", children: _jsx("div", { className: "font-secondary text-modal-gray-dark select-text text-sm font-normal tracking-[0.0075rem]", children: _jsx("span", { children: t('gated-tour.provide-email-description') }) }) }), _jsxs("div", { className: "border-y-modal-gray-light flex flex-col gap-[1.1875rem] border-y px-8 pb-[1.1875rem] pt-6", children: [_jsxs("div", { className: "flex flex-col gap-[0.6875rem]", children: [_jsx(Input, { className: "font-secondary w-full max-w-full gap-1 rounded-lg border", errorClassName: "leading-[0.9375rem] pt-0 tracking-[0.0075rem]", containerClassName: "leading-none", label: t('gated-tour.email-input-placeholder'), type: "email", value: email, error: emailError ? t(emailError) : '', onChange: onEmailChange, testId: "gated-email-input" }), _jsx(Input, { className: "font-secondary w-full max-w-full gap-1 rounded-lg border", errorClassName: "leading-[0.9375rem] pt-0 tracking-[0.0075rem]", containerClassName: "leading-none", label: t('contact.label-name'), type: "name", value: fullName, error: fullNameError, onChange: onFullNameChange, testId: "gated-full-name-input" }), _jsx(PhoneInput, { className: "h-[3.625rem]", value: fullPhoneNumber, error: phoneNumberError, onChange: onPhoneNumberChange })] }), _jsx(EmailConsentCheckBox, { checked: isEmailConsentAccepted, onChange: onEmailConsentChange })] }), _jsx("div", { className: "flex justify-end px-8 py-6", children: _jsx(TextButton, { className: "h-8 !min-w-[6.5rem] px-4 pb-[0.4375rem] pt-[0.3125rem] text-sm", disabled: email === '' ||
                        fullName === '' ||
                        isNationalNumberEmpty ||
                        !isEmailConsentAccepted ||
                        (requestEmailCooldownEmail === email && requestEmailCooldown > 0), onClick: onContinueClick, testId: "gated-provide-email-continue", children: _jsxs("span", { className: "whitespace-nowrap", children: [' ', `${t('gated-tour.continue')}${requestEmailCooldownEmail === email && requestEmailCooldown > 0 ? ` (${requestEmailCooldown})` : ''}`] }) }) })] }));
});
ProvideInfoView.displayName = 'ProvideInfoView';
export default ProvideInfoView;
