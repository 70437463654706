var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../../common/Modal';
import { useCookieConsentContext } from '../../contexts/useCookieConsentContext';
import useGlobalContext from '../../contexts/useGlobalContext';
import { useTranslation } from '../../contexts/useLocalizationContext';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import { saveVerificationTokenToCookies } from './utils/gatedTourUtils';
import { ProvideInfoView, VerifiedView, VerifyCodeView } from './views';
var GatedFlowStates;
(function (GatedFlowStates) {
    GatedFlowStates[GatedFlowStates["ProvideInfo"] = 0] = "ProvideInfo";
    GatedFlowStates[GatedFlowStates["VerifyEmail"] = 1] = "VerifyEmail";
    GatedFlowStates[GatedFlowStates["Verified"] = 2] = "Verified";
})(GatedFlowStates || (GatedFlowStates = {}));
/**
 * Default valid code in local and testing environments is `00000`.
 */
const GatedModal = React.memo(() => {
    const t = useTranslation();
    const { analytics, appContext } = useGlobalContext();
    const { isPerformanceCookieAllowed } = useCookieConsentContext();
    const { gatedTourService, layoutService, tourConfigService } = useServices();
    const { isGatedTourModalOpen } = useReactive(layoutService, ['isGatedTourModalOpen']);
    const { features, gatedTourEnabled } = useReactive(tourConfigService, ['features', 'gatedTourEnabled']);
    const { isVerificationNeeded, verificationToken, isVerified } = useReactive(gatedTourService, [
        'isVerificationNeeded',
        'verificationToken',
        'isVerified',
    ]);
    const [flowState, setFlowState] = useState(GatedFlowStates.ProvideInfo);
    const title = useMemo(() => {
        switch (flowState) {
            case GatedFlowStates.ProvideInfo:
                return t('gated-tour.provide-email-title');
            case GatedFlowStates.VerifyEmail:
                return t('gated-tour.verify-email-title');
            case GatedFlowStates.Verified:
            default:
                return '';
        }
    }, [flowState, t]);
    const onEmailRequested = useCallback(() => {
        setFlowState(GatedFlowStates.VerifyEmail);
    }, []);
    const onCodeVerified = useCallback(() => {
        setFlowState(GatedFlowStates.Verified);
    }, []);
    const onOutsideClick = useCallback(() => {
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'GATE', 'Outside');
        layoutService.isGatedTourModalOpen = false;
    }, [layoutService, analytics]);
    const onBackClick = useCallback(() => {
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'GATE', 'Back');
        setFlowState(GatedFlowStates.ProvideInfo);
    }, [analytics]);
    const onCloseClick = useCallback(() => {
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'GATE', 'Close');
        layoutService.isGatedTourModalOpen = false;
    }, [analytics, layoutService]);
    // Update isVerificationNeeded
    useEffect(() => {
        const newIsVerificationNeeded = !isVerified && appContext === 'standalone' && gatedTourEnabled && features.gatedTour;
        gatedTourService.isVerificationNeeded = newIsVerificationNeeded;
        if (newIsVerificationNeeded)
            layoutService.isGatedTourModalOpen = false;
        // Reset isVerificationNeeded when the modal is unmounted
        // The modal is unmounted only when tourConfigService.gatedTourEnabled is false
        // or if features.gatedTour is false
        // so unmounting guarantees that the verification is not needed
        return () => {
            gatedTourService.isVerificationNeeded = false;
        };
    }, [appContext, features.gatedTour, gatedTourEnabled, gatedTourService, isVerified, layoutService]);
    // Read verification token from cookies
    useEffect(() => {
        if (!isPerformanceCookieAllowed || !isVerificationNeeded)
            return;
        gatedTourService.readVerificationToken();
    }, [gatedTourService, isPerformanceCookieAllowed, isVerificationNeeded]);
    // Save verification token to cookies
    useEffect(() => {
        if (!isPerformanceCookieAllowed)
            return;
        if (!verificationToken)
            return;
        saveVerificationTokenToCookies(verificationToken);
    }, [isPerformanceCookieAllowed, verificationToken]);
    // Verify token when tour is gated from dev panel
    useEffect(() => {
        if (!isVerificationNeeded)
            return;
        const verifyToken = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield gatedTourService.verifyToken();
            }
            catch (error) {
                // Don't show any errors to users, it can fail silently, and the user will have to go through the verification process normally
            }
        });
        verifyToken();
    }, [gatedTourService, isVerificationNeeded]);
    useEffect(() => {
        if (!isGatedTourModalOpen)
            return;
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'GATE', 'Open');
    }, [analytics, isGatedTourModalOpen]);
    return (_jsxs(Modal, { title: title, show: isGatedTourModalOpen && appContext === 'standalone' && gatedTourEnabled && features.gatedTour, className: "z-gated-tour-modal", headerClassName: "[&&]:pt-6 [&&]:pb-4", headerBackButtonClassName: "[&&&]:h-[1.875rem] [&&]:mr-[-0.1875rem]", headerLabelClassName: `leading-[1.875rem] ${flowState === GatedFlowStates.ProvideInfo ? 'w-[21.625rem]' : ''}`, headerCloseButtonClassName: "[&&&]:h-[1.875rem]", showBackButton: flowState === GatedFlowStates.VerifyEmail, showCloseButton: flowState === GatedFlowStates.Verified, onBack: onBackClick, onClose: onCloseClick, onOutsideClick: onOutsideClick, children: [flowState === GatedFlowStates.ProvideInfo && _jsx(ProvideInfoView, { onEmailRequested: onEmailRequested }), flowState === GatedFlowStates.VerifyEmail && _jsx(VerifyCodeView, { onCodeVerified: onCodeVerified }), flowState === GatedFlowStates.Verified && _jsx(VerifiedView, {})] }));
});
GatedModal.displayName = 'GatedModal';
export default GatedModal;
